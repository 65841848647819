.logo {
  height: 32px;
  background: rgba(255, 255, 255, 0.2);
  margin: 16px;
}

.site-layout .site-layout-background {
  background: #fff;
}

.trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
}

.trigger:hover {
  color: #1890ff;
}

.ant-btn-primary {
  background-color: rgb(17, 14, 30);
  border-color: rgb(47, 40, 79);
}
.ant-btn-primary:hover {
  background-color: rgb(34, 37, 125);
  border-color: rgb(32, 36, 151);
}
.site-layout-background {
  padding: 15px;
}
.meter {
  padding-top: 30px;
  background-color: #fafafa !important;
}
.loginLeftside {
  background-color: rgb(29, 29, 44);
  /*background: url(loginbg.jpg);*/
  height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
}
.loginLeftside svg {
  opacity: 0.7;
}

.loginRightside {
  background: #000000;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.loginRightside .ant-input-affix-wrapper {
  border-radius: 24px;
}

.loginRightside .ant-card {
  border: 0;
  background: inherit;
}
.loginRightside .ant-card-body {
  height: 500px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  justify-content: space-between;
}

.loginRightside .ant-card h2 {
  color: #fff;
}
.loginRightside .ant-btn-primary {
  background-color: #180d93;
  border-color: #073552;
  height: 38px;
}
.loginRightside .mainicon {
  font-size: 64px;
  color: #fff;
}
.loginRightside input {
  padding: 4px;
}
.loginRightside .ant-form-explain {
  background: #ffb4b5;
  color: #fff;
  padding: 0 3px;
}
.ant-form-item-control {
  margin-bottom: 10px;
}
#loginForm {
  width: 300px;
}

.tox-toolbar__primary {
  background-color: #efefef !important;
}

#kbForm .ant-upload {
  margin-top: 15px;
  width: 100% !important;
  height: 200px;
}

#kbForm .ant-btn-primary {
  line-height: 40px;
  height: 70px;
}

.ant-menu-dark .ant-menu-inline.ant-menu-sub {
  background: inherit;
}
.ant-menu-sub.ant-menu-inline > .ant-menu-item,
.ant-menu-sub.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
  height: 24px;
  line-height: 24px;
}

.ant-menu-sub.ant-menu-inline > .ant-menu-item:before,
.ant-menu-sub.ant-menu-inline
  > .ant-menu-submenu
  > .ant-menu-submenu-title:before {
  color: #657d93;
  font-weight: bold;
  content: "- ";
}

.selectClient .ant-select-selector {
  border: none !important;
}

.ant-menu-dark .ant-divider .ant-divider-inner-text {
  color: hsla(0, 0%, 100%, 0.65);
}

ul.ant-list-items li:hover {
  background-color: #eaeaea;
}

.showOnlyForPrint {
  display: none;
}
.hide {
  display: none;
}

.kbPostViewDetail img {
  width: auto;
  height: auto;
  max-width: 100%;
}

.galleryUpload .ant-upload-list div {
  max-width: 300px;
}

@page {
  size: auto; /* auto is the initial value */
  /* this affects the margin in the printer settings */
  margin: 25mm 25mm 25mm 25mm;
}

@media print {
  .printPageButton {
    display: none;
  }
  .showOnlyForPrint {
    display: block;
  }
  .kbPostViewDetailContent {
    display: flex;
    flex-direction: column-reverse;
  }
}

@media only screen and (max-width: 600px) {
  .loginLeftside {
    display: none;
  }
  #loginForm {
    width: 98%;
  }
  .selectClient {
    max-width: 100%;
  }
  .clientLabel {
    display: none;
  }
  /*
    .ant-layout-sider-collapsed{
        display: none;
    }
    */
}

.descriptionView .ant-descriptions-item-label {
  font-weight: bold;
  display: block;
  margin-top: 18px;
}
.descriptionView .ant-descriptions-row > td,
.descriptionView .ant-descriptions-row > th {
  padding-bottom: 2px;
}

.descriptionView2 {
  max-width: 900px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.descriptionView2 div.box {
  border: 1px solid #ddd;
  padding: 10px;
  width: 50%;
}
.descriptionView2 div.box-row {
  border: 1px solid #ddd;
  padding: 10px;
  width: 100%;
}

.descriptionView2 .ant-descriptions-item-label {
  font-weight: bold;
  display: block;
  margin-top: 18px;
}
.descriptionView2-heading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  justify-items: center;
  margin-bottom: 20px;
}
.descriptionView2-heading .ant-typography {
  margin: 2px !important;
  padding: 0px !important;
}

.flex {
  display: flex;
}
.flex-row {
  flex-direction: row;
}
.flex-col {
  flex-direction: column;
}

.h-10 {
  line-height: 10px;
}

.bg-black {
  background: #000;
  display: flex;
  color: #fff;
}

.mt-10 {
  margin-top: 10px;
}
.text-green {
  color: green;
}
.text-yellow {
  color: rgba(89, 89, 9, 0.612);
}

.table-row-light {
  background-color: #ffffff;
}
.table-row-dark {
  background-color: #fbfbfb;
}

.swal2-styled.swal2-confirm {
  background-color: rgb(83, 51, 237) !important;
  border-color: rgb(83, 51, 237) !important;
  border-radius: 50px !important;
  min-width: 120px !important;
}
.swal2-cancel.swal2-styled {
  border-radius: 50px !important;
  min-width: 120px !important;
}
.swal2-container.swal2-backdrop-show,
.swal2-container.swal2-noanimation {
  background: rgba(0, 0, 0, 0.2) !important;
}

.swal2-popup {
  border: 4px solid rgb(83, 51, 237) !important;
}

ul.ant-menu li.menuHeading:before {
  display: none !important;
}
ul.ant-menu li.menuHeading {
  height: 40px !important;
  background-color: #060735;
}
ul.ant-menu li.menuHeading .ant-divider::before {
  display: none !important;
}
ul.ant-menu li.menuHeading .ant-divider span {
  font-size: 18px;
  padding: 6px 2px 2px 6px;
}

.ant-layout-header {
  height: 55px;
  line-height: 55px;
}
.ant-form-item {
  margin: 0 0 12px;
}
 
.ant-page-header {
  padding: 10px 24px;
}